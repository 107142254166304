<template>
   <div id="contact" class="relative">

      <!-- Big title -->
      <p id="direction3">
         <span class="big_title" data-scroll="" data-scroll-direction="horizontal" data-scroll-speed="-8" data-scroll-target="#direction3" >Contact <span>Contact</span> Contact <span>Contact</span></span>
      </p>
      <div class="container">

      </div>
      <!-- VueJS -->
      <div class="vuejs">
         <img src="@/assets/img/logo-vuejs.png"><p>Site developpé avec vue.js</p>
      </div>
   </div>
</template>

<script>
import { TimelineLite, gsap  } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
   name: 'Contact',
   data () {
      return {
      }
   },
   mounted() {
      // init gsapAnimation
      setTimeout(() => { this.gsapAnimation() }, 300);
 
   },
   methods: {
      gsapAnimation(){
         const { bigTitle } = this.$refs
         const pageContainer = document.querySelector('[data-scroll-container]')
         const clientHeight = document.documentElement.clientHeight
      }
   },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/global.scss';
#contact{
   position:relative;
   z-index: 55;
   min-height:700px;
   background:#000;
   padding-top: 2vh;
}

#direction3{
    height: 200px;
    margin:-23vh 0 0 0;
}
 
.vuejs{
   position:absolute;
   right:0;
   bottom:0;
   z-index: 10;
   display: flex;
   align-items: center;
   font-size:14px;
   font-weight: 500;
   color:#fff;
   p{
      background:#41b883;
      display: block;
      height:100%;
      padding:15px;
      margin:0
   }
   img{
      height:38px;
      margin-right:10px;
   }
}

@media screen and (max-width: 980px) {
   #contact{padding-top:0;  }
   #contact #direction3{ margin-top: -13vh;}
}
</style>