<template>
    <div>
        <!-- NEXT -->
        <div class="next hover_project" ref="wrappNext" :style="{backgroundColor:`${this.color}`}" @click="clickProject" :data-color="this.color">
            <div class="wrapp_visuel" ref="wrappVisuel">
                <img class="visuel" :src="require(`@/assets/img/${mainImage}`)" >
            </div>
            <div class="wrapp_title">
                <p class="title" ref="title">Projet suivant</p>
            </div>
        </div>
        <div class="bottom"></div>
    </div>
</template>
 
<script>
import { TimelineLite, gsap  } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"

export default {
    name: 'Next',
    props: {
      color: {type: String, require: true},
      imageName: {type: String, require: true},
      imageNameMobile: {type: String, require: true},
      nextProject: {type: String, require: true},
    },
    data() {
        return {
            mainImage: ''
        }
    },
    mounted () {
      
    }, 
    beforeMount() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
         this.mainImage = this.imageNameMobile
      } else {
         this.mainImage = this.imageName
      }
   },
    methods: { 
        clickProject(){
            const pageContainer = document.querySelector('#app');    
            var that = this;
            var navigate = this.$router;
            const { wrappNext, title } = this.$refs
            wrappNext.style.zIndex = '100'

            setTimeout(() => {
                gsap.to(wrappNext, {
                    scrollTrigger: {scroller: pageContainer, trigger: wrappNext, start: `top+=100 bottom`, end: 'bottom-=300 bottom', toggleActions: 'play play play reverse'},
                    height: '100vh', duration: 0.5, ease: 'easeIn'    
                });
                gsap.to(title, {
                    scrollTrigger: {scroller: pageContainer, trigger: title, start: `top+=100 bottom`, end: 'bottom-=300 bottom', toggleActions: 'play play play reverse'},
                    transform: 'translateY(100%)', duration: 0.6, delay: 0.4, ease: 'easeIn'    
                }); 
                that.$emit('receive-click-project', true)
                
                setTimeout(() => {
                    navigate.push({ name: this.nextProject, params: { nextProject: true }})
                }, 800);
            }, 300);
        },
      
    },
}
</script>

<style scoped lang="scss">
.next{
    height:40vh;
    overflow: hidden;
    width:100%;
    position:fixed;
    bottom:0;
    cursor:pointer;
    &::after{
        content:'';
        height:100%;
        width:100%;
        position:absolute;
        top: 0;
        left:0;
    }
    .wrapp_visuel{
        display: block;
        height:100vh;
        opacity:1; 
        .visuel{
            display: block;
            width: 100%;
            height:100%;
            object-fit: cover;
            object-position: center;
        } 
    }
    .wrapp_title{
        overflow: hidden;
        position:absolute;
        top:50%;
        left:50%;
        width: 100%;
        transform: translateX(-50%) translateY(-50%);
        .title{
            color:#fff;
            font-weight:800;
            font-size:75px;
            margin: 0;
            transform:translateY(0%)
        }
    }
}
@media screen and (max-width: 1580px) {
    .next .wrapp_title .title {font-size: 5vw;}
}
@media screen and (max-width: 980px) {
    .next .wrapp_title .title {font-size: 6vw;}
}
@media screen and (max-width: 780px) {
    .next .wrapp_title .title {font-size: 7vw;}
}
@media screen and (max-width: 580px) {
    .next .wrapp_title .title {font-size: 8vw;}
}
</style>