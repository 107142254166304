<template>
  <div id="container">
    <Loader/>

    <div id="app" data-scroll-container>
      <CursorCirle/>    
      <router-view/>
    </div>
    <div class="noise"></div>

  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import CursorCirle from '@/components/CursorCirle.vue'
export default {
    name: 'App',
    metaInfo: {
      title: 'Usubelli Sylvain | Développeur Front',
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: 'Usubelli Sylvain | Développeur Front ... Meta description' },
        // { name: 'viewport', content: 'user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, viewport-fit=cover' },
        // { name: 'theme-color', content: '#ea3752' }
      ]
    },
    components: {
      Loader,
      CursorCirle
    },
}
</script>
 
