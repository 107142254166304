<template>
   
</template>
<script>
import { TimelineLite, gsap, TweenMax  } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import LocomotiveScroll from 'locomotive-scroll';
export default {
   name: 'LocoScroll',
   props: {
      destroyBool: {type: Boolean, require: true},
      scrollToProjectId: {type: Number, require: true}
   },
   data() {
      return {
         locoScroll: null,
      }
   },
   mounted () {
      this.initLocomotive()
      this.scrollToOnClick()
      this.clickNextProject()
      if(!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
         this.initCustomCursor()
      }
   },
   methods: {
      initLocomotive(){
         
         if (this.locoScroll !== null) {
            this.locoScroll.stop();
            this.locoScroll.destroy();
         }
         
         gsap.registerPlugin(ScrollTrigger)
         const pageContainer = document.querySelector('#app');
         
         var these = this;
         var scroller = null;

         this.locoScroll = scroller = new LocomotiveScroll({
            el: pageContainer,
            smooth: true,
            touchMultiplier: 2,
            reloadOnContextChange: true,
            smartphone: {
               smooth: 1,
               breakpoint: 767
            },
            tablet: {
               smooth: 1,
               breakpoint: 1024
            },
            getSpeed: true,
            getDirection: true,
         })
         
         ScrollTrigger.scrollerProxy(pageContainer, {
            scrollTop(value) {
               these.$emit('receive-positionY', scroller.scroll.instance.scroll.y)
               return arguments.length ? scroller.scrollTo(value, 0, 0) : scroller.scroll.instance.scroll.y;
            }, 
            getBoundingClientRect() {return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};}
         }); 
         this.locoScroll.on("scroll", ScrollTrigger.update);
         ScrollTrigger.addEventListener("refresh", () => scroller.update());
         ScrollTrigger.refresh();

      },
      clickNextProject(){
         var these = this;
         document.querySelector('.next.hover_project').addEventListener('click', function(e){
            these.locoScroll.scrollTo(document.querySelector('.bottom'), {duration: 0, offset: 100 });
         })
      },
      scrollToOnClick(){
         // Scroll TO on click
         var buttonScrollTo = document.querySelector('.scroll_bottom.proj');
         var these = this;
         buttonScrollTo.addEventListener('click', function(e){
            e.preventDefault();
            var description = document.querySelector('#description');
            var windowHeight = window.innerHeight;
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
               var offsetHeight = - (windowHeight / 2.3)
            } else {
               var descriptionHeight = description.offsetHeight;
               var offsetHeight = descriptionHeight - windowHeight + 50
            }

            these.locoScroll.scrollTo(description, {duration: 400, offset: offsetHeight });
         })
      },
      initCustomCursor(){
         const circle = document.querySelector(".circle");
         const pageContainer = document.querySelector('#app');
         container.addEventListener("mousemove", function(e) {
            if(!circle.classList.contains('load')){
               setTimeout(() => {circle.classList.add('load')}, 350);
            }

            var relY = e.pageY;
            var relX = e.pageX - pageContainer.offsetLeft;
            TweenMax.to(circle, 0.4, { x: relX, y: relY });
         });
         this.locoScroll.on('scroll', (instance) => {
           
            var positionScrollY = instance.scroll.y
            TweenMax.to(circle, 0, { top: positionScrollY +'px' });
         }); 

         // HOVER ELEMENT
         const hoverElement = document.querySelectorAll('.hover_element');
         hoverElement.forEach(item => {
            item.addEventListener('mouseover', (e) => {
               circle.classList.add('hoverElement')
            })
            item.addEventListener('mouseleave', (e) => {
               circle.classList.remove('hoverElement')
            })
            item.addEventListener('click', (e) => {
               circle.classList.remove('hoverElement')
            })
         })

         // HOVER PROJECT
         const hoverProject = document.querySelectorAll('.hover_project');
         hoverProject.forEach(item => {
            item.addEventListener('mouseover', (e) => {
               circle.classList.add('hoverProject')
               circle.querySelector('.wrapp').style.backgroundColor = e.target.dataset.color
            })
            item.addEventListener('mouseleave', (e) => {
               circle.classList.remove('hoverProject')
               circle.querySelector('.wrapp').style.backgroundColor = '#ddd'
            }) 
            item.addEventListener('click', (e) => {
               circle.classList.remove('hoverProject')
               circle.querySelector('.wrapp').style.backgroundColor = "#ddd"
            })
         })
         
         // HOVER VIDEO
         const hoverVideo = document.querySelectorAll('.hover_video');
         hoverVideo.forEach(item => {
            item.addEventListener('mouseover', (e) => {
               circle.classList.add('hoverVideo')
               console.log(e.target)
               circle.querySelector('.wrapp').style.backgroundColor = e.target.dataset.color
            })
            item.addEventListener('mouseleave', (e) => {
               circle.classList.remove('hoverVideo')
               circle.querySelector('.wrapp').style.backgroundColor = '#ddd'
            })
         })
       

      },     
      destroyLoco(){
         this.locoScroll.stop();
         this.locoScroll.destroy()
      }
   },
   watch: {
      destroyBool: function(newVal){
         if (newVal === true){
            this.destroyLoco()
         }
      },
   }
}
</script>
