<template>
   <!-- Top  -->
   <div id="top" :style="{backgroundColor:`${this.bgColor}`}">

      <!-- Visuel -->
      <div class="wrapp_visuel">
         <img class="visuel" :src="require(`@/assets/img/${mainImage}`)">
      </div>

      <!-- Title -->
      <h1 class="title up_word"><span>{{this.projectName}}</span></h1>

      <!-- Overlay -->
      <div class="overlay"></div>

      <!-- Arrow -->
      <div class="scroll_bottom proj hover_element">
         <svg height="200" width="200">
            <circle class="circle" cx="100" cy="100" r="95" stroke="#fff" stroke-width="3" fill-opacity="0" />
         </svg>
         <div class="background"></div>
         <div class="wrapp_arrow ">
            <img class="arrow" src="@/assets/svg/arrow.svg">
         </div>
      </div>
   </div>
</template>
 
<script>
import { TimelineLite, gsap  } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"

export default {
   name: 'TopProjet',
   components: {
   },
   props: {
      imageName: {type: String, require: true},
      imageNameMobile: {type: String, require: true},
      projectName: {type: String, require: true},
      bgColor: {type: String, require: true},
    },
   data() {
      return {
         mainImage: ''
      }
   },
   beforeMount() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
         this.mainImage = this.imageNameMobile
      } else {
         this.mainImage = this.imageName
      }
   },
   mounted () {
      document.querySelector('#top').classList.add('load');
      this.gsapAnimation();
   }, 
   methods: {
       gsapAnimation(){
            const pageContainer = document.querySelector('#app');      
            var windowHeight = window.innerHeight;
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
               var titlePosition = (windowHeight / 4) +'px';
            } else {
               var titlePosition = (windowHeight / 6) +'px';
            }

            gsap.to('#top .title', {
               scrollTrigger: {scroller: pageContainer, trigger: '#top', start: `top`, end: `bottom+=30% center`, scrub: true},
               transform:  `translateX(-50%) translateY(${titlePosition})`, 
            }) 
            gsap.to('#top .wrapp_visuel', {
               scrollTrigger: {scroller: pageContainer, trigger: '#top', start: `top`, end: `1200px 400px`, scrub: true},
               transform: 'translateY(20%)', 
            }) 
            gsap.to('#top .scroll_bottom', {
               scrollTrigger: {scroller: pageContainer, trigger: '#top', start: `top`, end: `600px 400px`, scrub: true},
               opacity: '0',  
            }) 
            gsap.to('#top .wrapp_visuel', {
               scrollTrigger: {scroller: pageContainer, trigger: '#top', start: `top+=300 top`, end: `1200px 400px`, scrub: true},
               opacity: '0.4', 
            }) 
      },
      
   },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/global.scss';
#top{
   height:calc(100vh - calc(100vh - 100%));
   width:100%;
   position: relative;
   overflow: hidden;
   .wrapp_visuel{
      display: block;
      height:100vh;
      .visuel{
         display: block;
         width: 100%;
         height:100%;
         object-fit: cover;
         object-position: center;
      }
      &::after{
         content:'';
         position:absolute;
         top:0;
         left:0;
         width:100%;
         height:100%;
         background:rgba(0,0,0,0.1);
         z-index: 1;
         opacity: 0;
      }
   }
   &.load .wrapp_visuel::after{
      animation: fadeIn 0.6s cubic-bezier(.87,.01,.83,.67) forwards 0.5s;
   }
   
   
   .title{
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translateX(-50%) translateY(-50%);
      color: #fff;
      font-size: 9vw;
      margin: 0;
      line-height: 1.2;
      z-index: 10;
   }
   &.load .title span{
      animation: upWord 1s cubic-bezier(.87,.01,.83,.67) forwards 0s;
   }
   .scroll_bottom{
      position: absolute;
      z-index: 10;
      left: 50%;
      bottom: 2%;
      cursor:pointer;
      transform: translateX(-50%) scale(0.6);
      .wrapp_arrow{
         opacity: 0;
         transition-duration: 0.6s;
         left: 50%;
         top: 50%;
         transform: translateX(-50%) translateY(-65%);
         position: absolute;
         .arrow{
            width: 80px;
            transition-duration: 0.3s;
            filter:invert(1);
         }
      }
      .background{
         height: 0;
         width: 0;
         border-radius: 100%;
         background: #fff;
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translateX(-50%) translateY(-50%);
         transition-duration: 0.4s;
      }
      .circle {
         stroke-dasharray: 1000;
         opacity: 0.7;
         stroke-dashoffset: 1000;
      }
      &:hover{
         .background{
            height:97%;
            width:97%;
         }
         .arrow{
            filter:invert(0);
            transition-duration: 0.3s;
            transform: translateY(10%);
         }
      }
   }
   &.load .scroll_bottom .wrapp_arrow{
      opacity: 1;
      transition-delay: 1.4s;
      transform: translateX(-50%) translateY(-50%);
   }
   &.load .scroll_bottom .circle {
      animation: stroke 1.3s cubic-bezier(.87,.01,.83,.67) forwards 0.7s;
   }
   @keyframes stroke {
      to {
         stroke-dashoffset: 0;
      }
   }
}

@media screen and (max-width: 980px) {
   #top .scroll_bottom {bottom: 20px; transform: translateX(-50%) scale(0.55);}
   #top .title {font-size: 12vw;}
}
@media screen and (max-width:780px) {
   #top .scroll_bottom {transform: translateX(-50%) scale(0.5);}
   #top .title  {top: 45%;}
}
@media screen and (max-width:480px) {
   #top .scroll_bottom {transform: translateX(-50%) scale(0.48);}
   #top .title {font-size: 14vw;}
}
</style>