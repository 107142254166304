<template>
   <div id="projet" ref="loadIntroProject">
      <!-- BACK BUTTON -->
      <BackButton/>

      <!-- LOCOSCROLL INIT -->
      <LocoScrollProject :destroyBool="this.destroyLocoScroll"/>

      <!-- TOP  -->
      <TopProjet projectName="Dezyr" imageName="dezyr/main-image.jpg" imageNameMobile="dezyr/main-image-mobile.jpg" :bgColor="this.color"/>

      <!-- DESCRIPTION -->
      <div id="description" :style="{backgroundColor:`${this.color}`}">
         <div class="container">
            <div class="flex">
               <!-- Details -->
               <div class="details">
                  <div data-scroll="" data-scroll-delay="0.2" data-scroll-speed="0.5">
                     <!-- col -->
                     <div class="row">
                        <!-- Wrapp -->
                        <div class="wrapp">
                           <p class="title">Client</p>
                           <div class="info">
                              <p>Projet perso</p>
                           </div>
                        </div>
                        <!-- Wrapp -->
                        <div class="wrapp">
                           <p class="title">Date</p>
                           <div class="info">
                              <p>octobre 2019</p>
                           </div>
                        </div>
                     </div>

                     <!-- col -->
                     <div class="row">
                        <!-- Wrapp -->
                        <div class="wrapp">
                           <p class="title">Missions</p>
                           <div class="info">
                              <p>UI, UX webdesign</p>
                              <p>Intégration</p>
                              <p>Développement Front & Back</p>
                              <p>SEO / Marketing</p>
                           </div>
                        </div>
                        <!-- Wrapp -->
                        <div class="wrapp">
                           <p class="title">Techno</p>
                           <div class="info">
                              <p>Prestashop</p>
                              <p>PHP</p>
                              <p>Javascript</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  
               </div>

               <!-- Concept -->
               <div class="concept" data-scroll="" data-scroll-delay="0.1" data-scroll-speed="0.5">
                  <!-- Title -->
                  <div class="title">
                     <p class="up_word"><span>Lorem ipsum dolor sit amet,</span></p>
                     <p class="up_word"><span>consectetur adipiscing elit</span></p>
                     <p class="up_word"><span>sed do eiusmod tempor.</span></p>
                  </div>
                  <!-- Resume -->
                  <div class="resume">
                     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                  </div>
               </div>
            </div>
            <a href="https://dezyr.fr/" target="_blank" data-scroll="" data-scroll-delay="0.1" data-scroll-speed="0.5" class="link hover_project" :data-color="this.color">
               <span class="wrapp"><span class="text">Visiter le site web</span></span>
            </a>
         </div>
      </div>

      <!-- VISUELS  -->
      <div id="visuels" >

         <div class="container">
            <Video :scrollSpeed="1.5" videoName="dezyr/teaser-dezyr-final-cut.mp4" side="left" :color="this.color" poster="dezyr/poster-dezyr-teaser2.jpg">
               <p slot="title">Lorem ipsum incididunt labore</p>
               <p slot="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
            </Video> 
            
            <Visuel :scrollSpeed="0.5" imageName="dezyr/homepage.jpg" side="right" :big="true">
               <p slot="title">Lorem ipsum incididunt labore</p>
               <p slot="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
            </Visuel>

            <Visuel :scrollSpeed="0.5" imageName="dezyr/jeux-coquins.jpg" side="left" :big="true">
               <p slot="title">Lorem ipsum incididunt labore</p>
               <p slot="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
            </Visuel>

            <Visuel :scrollSpeed="0.5" imageName="dezyr/tests.jpg" side="right" :big="true">
               <p slot="title">Lorem ipsum incididunt labore</p>
               <p slot="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
            </Visuel>

         </div>
   
         <section id="sectionPin">
            <div class="pin-wrap">
               <img class="first horz" :src="require(`@/assets/img/dezyr/mockup-mobile-1.jpg`)">
               <img class="" :src="require(`@/assets/img/dezyr/mockup-mobile-2.jpg`)">
               <img class="horz" :src="require(`@/assets/img/dezyr/mockup-mobile-3.jpg`)">
            </div>
         </section>

      </div> 

      <!-- NEXT  -->
      <Next color="#e20612" imageName="adrenaline/main-image.jpg" imageNameMobile="adrenaline/main-image-mobile.jpg" nextProject="Adrenaline" @receive-click-project="DestroyLocoScroll"/>

   </div>
</template>
 
<script>
import Visuel from '@/components/projet/Visuel.vue'
import Video from '@/components/projet/Video.vue'
import Next from '@/components/projet/Next.vue'
import BackButton from '@/components/projet/BackButton.vue'
import LocoScrollProject from '@/components/projet/LocoScrollProject.vue'
import TopProjet from '@/components/projet/TopProjet.vue'
import { TimelineLite, gsap  } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"

export default {
   name: 'Dezyr',
   metaInfo: {
      title: 'Dezyr',
   },
   components: {
      LocoScrollProject,
      TopProjet,
      Video,
      Next,
      BackButton,
      Visuel
   },
   data() {
      return {
         destroyLocoScroll: false,
         color: "#d77287"
      }
   },
   beforeMount() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
         var metaThemeColor = document.querySelector("meta[name=theme-color]");
         metaThemeColor.setAttribute("content", this.color);
      } else {
         const circle = document.querySelector(".circle");
         circle.classList.remove('hoverProject')
         circle.classList.remove('hoverElement')
         circle.querySelector('.wrapp').style.backgroundColor = '#ddd'
      }
   },
   mounted () {
      this.$store.dispatch('updateProjectId', 2)
      this.$store.dispatch('updateColor', this.color)
      this.gsapAnimationTop();
      setTimeout(() => {
         this.gsapAnimationVisuel()
      }, 4500); 
   }, 
   beforeRouteLeave (to, from, next) {
      document.querySelector('.circle').classList.remove('hoverProject')
      document.querySelector('.circle .wrapp').style.backgroundColor = "#ddd"
      if(to.params.nextProject == true){
         this.DestroyLocoScroll()
         next()
      } else {
         this.$store.dispatch('goLoader', true)
         this.DestroyLocoScroll()
         setTimeout(() => {
            next()
         }, 500);
      }
      
      const triggers = ScrollTrigger.getAll();
      triggers.forEach((trigger) => {
         trigger.kill();
      })
   },
   methods: {
      gsapAnimationTop(){
            const pageContainer = document.querySelector('#app');    
            const clientHeight = document.documentElement.clientHeight      
            
            // DESCRIPTION
             gsap.to('.concept .up_word span', {
            scrollTrigger: {scroller: pageContainer, trigger: '#description .concept .title', toggleActions: 'play play play reverse', start: `center bottom`},
            transform: 'translateX(0)', duration: 0.3, stagger: 0.2,ease: 'easeIn'})
            gsap.to('.concept .resume', {
            scrollTrigger: {scroller: pageContainer, trigger: '#description .concept .resume', toggleActions: 'play play play reverse', start: `center bottom`},
            transform: 'translateX(0)', opacity:1,  duration: 0.5, ease: 'easeIn'})   
            gsap.to('#description .details', {
            scrollTrigger: {scroller: pageContainer, trigger: '#description .details', toggleActions: 'play play play reverse', start: `center bottom`},
            transform: 'translateX(0)', opacity:1,  duration: 0.5, ease: 'easeIn'})  
            gsap.to('#description .link .wrapp', {
            scrollTrigger: {scroller: pageContainer, trigger: '#description .details', toggleActions: 'play play play reverse', start: `center bottom`},
             opacity:1,  duration: 0.5, ease: 'easeIn'});   
         
      },
      gsapAnimationVisuel(){

         const pageContainer = document.querySelector('#app'); 

         // Scroll horizontal
         if(document.querySelector(".pin-wrap")){
               let pinWrap = document.querySelector(".pin-wrap");
               let pinWrapWidth = pinWrap.offsetWidth;
               let pinWrapWidthFinal = pinWrapWidth / 1.5;
               let horizontalScrollLength = pinWrapWidth - window.innerWidth;
   
               gsap.to(".pin-wrap", {
                  scrollTrigger: {
                     scroller: pageContainer,
                     scrub: true,
                     trigger: "#sectionPin",
                     pin: true,
                     scrub: 0.7,
                     start: "top top",
                     end: 'bottom+='+pinWrapWidthFinal + ' bottom'
                  },
                  x: -horizontalScrollLength,
               }); 
         }
         ScrollTrigger.refresh();

         gsap.to(document.querySelectorAll('.pin-wrap img.first'), {
            scrollTrigger: {scroller: pageContainer, trigger: document.querySelectorAll('#sectionPin'), start: `top center`, toggleActions: 'play play play reverse'},
            transform: 'translateX(0)', opacity:1, duration: 0.8, ease: 'easeIn'         
         });   
            
      },
      DestroyLocoScroll(){
         this.destroyLocoScroll = true
      },
     
      goBack(){
         this.$store.dispatch('goLoader', true)
       
         this.$nextTick( () => {
            var navigate = this.$router;
            navigate.push({ name: 'Home' })
         })
      }, 
   },
}
</script>

<style scoped>
   #projet #sectionPin{
      background: #d57287;
   }
</style>
 