<template>
     <!-- Go back -->
      <div class="btn_back hover_element" @click="goBack">
        <div v-if="mobile">
            <img class="arrow" src="@/assets/svg/grey-arrow.svg"> Retour
        </div>
        <div v-else data-scroll="" data-scroll-sticky="" data-scroll-target="#projet">
            <img class="arrow" src="@/assets/svg/grey-arrow.svg"> Retour
        </div>
      </div>
</template>
 
<script>
import { TimelineLite, gsap  } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"

export default {
    name: 'BackButton',
    props: {
      color: {type: String, require: true},
      imageName: {type: String, require: true},
      nextProject: {type: String, require: true},
    },
    data() {
        return {
            mobile: false,
        }
    },
    mounted () {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            this.mobile = true
        }
    }, 
    methods: {
        goBack(){
            this.$store.dispatch('goLoader', true)
            this.$nextTick( () => {
                var navigate = this.$router;
                navigate.push({ name: 'Home' })
            })
        }, 
    },
}
</script>

<style scoped lang="scss">
 .btn_back{
      cursor:pointer;
      position:absolute;
      top:30px;
      left:30px;
      z-index: 100;
      color:#eee;
      font-weight:600;
      font-size:19px;
      transform:translateY(-300%);
      display: flex;
      align-items: center;
      animation: downBackBtn 1.3s cubic-bezier(.87,.01,.83,.67) forwards 1s;
      .arrow{
         width:13px;
         transform:rotate(90deg);
         margin-right: 10px;
      }
   }
</style>