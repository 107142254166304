<template>
   <div id="projet" ref="loadIntroProject">
      <!-- BACK BUTTON -->
      <BackButton/>

      <!-- LOCOSCROLL INIT -->
      <LocoScrollProject :destroyBool="this.destroyLocoScroll"/>

      <!-- TOP  -->
      <TopProjet projectName="Adrenaline" imageName="adrenaline/main-image.jpg" imageNameMobile="adrenaline/main-image-mobile.jpg" :bgColor="this.color"/>

      <!-- DESCRIPTION -->
      <div id="description" :style="{backgroundColor:`${this.color}`}">
         <div class="container">
            
            <div class="flex">
               <!-- Details -->
               <div class="details">
                  <div data-scroll="" data-scroll-delay="0.2" data-scroll-speed="0.5">
                     <!-- col -->
                     <div class="row">
                        <!-- Wrapp -->
                        <div class="wrapp">
                           <p class="title">Client</p>
                           <div class="info">
                              <p>En agence</p>
                           </div>
                        </div>
                        <!-- Wrapp -->
                        <div class="wrapp">
                           <p class="title">Date</p>
                           <div class="info">
                              <p>Juillet 2017</p>
                           </div>
                        </div>
                     </div>

                     <!-- col -->
                     <div class="row">
                        <!-- Wrapp -->
                        <div class="wrapp">
                           <p class="title">Missions</p>
                           <div class="info">
                              <p>UI, UX design</p>
                              <p>Intégration</p>
                              <p>Développement Front & Back</p>
                           </div>
                        </div>
                        <!-- Wrapp -->
                        <div class="wrapp">
                           <p class="title">Techno</p>
                           <div class="info">
                              <p>Drupal</p>
                              <p>PHP</p>
                              <p>Javascript</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  
               </div>

               <!-- Concept -->
               <div class="concept" data-scroll="" data-scroll-delay="0.1" data-scroll-speed="0.5">
                  <!-- Title -->
                  <div class="title">
                     <p class="up_word"><span>Adrénaline propose son expertise basée</span></p>
                     <p class="up_word"><span>sur la combinaison de compétences issues</span></p>
                     <p class="up_word"><span>de l'univers de l'alpinisme et du bâtiment.</span></p>
                  </div>
                  <!-- Resume -->
                  <div class="resume">
                     <p>Adrenaline souhaitait développer son activité avec une présence digitale, l'enjeu etait donc de créer un site vitrine permettant de mettre en avant l’expertise et le savoir-faire d’Adrenaline dans le domaine du chantier en hauteur.</p>
                     <p>Un back office personnalisé à été developpé sur Drupal afin de faciliter la mise à jour du contenus des differentes pages.</p>
                  </div>
                     

               </div>
            </div>
            <a href="https://adrenaline-ee.com/" target="_blank" data-scroll="" data-scroll-delay="0.1" data-scroll-speed="0.5" class="link hover_project" :data-color="this.color">
               <span class="wrapp"><span class="text">Visiter le site web</span></span>
            </a>
         </div>
      </div>

      <!-- VISUELS  -->
      <div id="visuels" >

         <div class="container">
            <Video :scrollSpeed="1.5" videoName="adrenaline/home.mp4" side="left" :color="this.color" poster="adrenaline/home-section.jpg">
               <p slot="title">Animation vertical au scroll</p>
               <p slot="content">Le design du site composé de grande lignes vertical sur toute la hauteur des pages, a été pensé pour rappeler le cordage de securité des techniciens.</p>
            </Video> 

            <Video :scrollSpeed="1.5" videoName="adrenaline/menu.mp4" side="right" :color="this.color" poster="adrenaline/menu-open.jpg">
               <p slot="title">Menu de navigation</p>
            </Video> 

            <Video :scrollSpeed="1.5" videoName="adrenaline/slider.mp4" side="left" :color="this.color" poster="adrenaline/slider.jpg">
               <p slot="title">Slider Vertical</p>
               <p slot="content">Les visuels du slider ont été segmentés en plusieurs colonnes pour une apparition en differé afin de rendre une impression de vertiges.</p>
            </Video> 

            <Visuel :scrollSpeed="0.5" imageName="adrenaline/chantier-full.jpg" side="right" :big="true">
               <p slot="title">Lorem ipsum incididunt labore</p>
               <p slot="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
            </Visuel>
            
            <Video :scrollSpeed="1.5" videoName="adrenaline/chantier.mp4" side="left" :color="this.color" poster="adrenaline/chantier.jpg">
               <p slot="title">Pages interieurs</p>
               <p slot="content">Au scroll, toutes les pages affichent aleatoirement l'animation des blocs de contenu.</p>
            </Video> 

            <!-- <Visuel :scrollSpeed="0.5" imageName="adrenaline/home-page.jpg" side="left" :big="true">
            </Visuel> -->
         </div>
   
         <section id="sectionPin">
            <div class="pin-wrap">
               <!-- <img class="first" :src="require(`@/assets/img/adrenaline/mobile-4.jpg`)"> -->
               <img class="first horz" :src="require(`@/assets/img/adrenaline/mobile-1.jpg`)">
               <img class="" :src="require(`@/assets/img/adrenaline/mobile-2.jpg`)">
               <img class="horz" :src="require(`@/assets/img/adrenaline/mobile-3.jpg`)">
            </div>
         </section>

      </div> 

      <!-- NEXT  -->
      <Next color="#843680" imageName="sexbattle/main-image.jpg" imageNameMobile="sexbattle/main-image-mobile.jpg" nextProject="Sexbattle" @receive-click-project="DestroyLocoScroll"/>
      
   </div>
</template>
 
<script>
import Visuel from '@/components/projet/Visuel.vue'
import Video from '@/components/projet/Video.vue'
import Next from '@/components/projet/Next.vue'
import BackButton from '@/components/projet/BackButton.vue'
import LocoScrollProject from '@/components/projet/LocoScrollProject.vue'
import TopProjet from '@/components/projet/TopProjet.vue'
import { TimelineLite, gsap  } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"

export default {
   name: 'Adrenaline',
   metaInfo: {
      title: 'Adrenaline',
   },
   components: {
      LocoScrollProject,
      TopProjet,
      Video,
      Next,
      BackButton,
      Visuel
   },
   data() {
      return {
         destroyLocoScroll: false,
         color: "#e20612"
      }
   },
   beforeMount() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
         var metaThemeColor = document.querySelector("meta[name=theme-color]");
         metaThemeColor.setAttribute("content", this.color);
      } else {
         const circle = document.querySelector(".circle");
         circle.classList.remove('hoverProject')
         circle.classList.remove('hoverElement')
         circle.querySelector('.wrapp').style.backgroundColor = '#ddd'
      }
   },
   mounted () {
      this.$store.dispatch('updateProjectId', 3)
      this.$store.dispatch('updateColor', this.color)
      this.gsapAnimationTop();
      setTimeout(() => {
         this.gsapAnimationVisuel()
      }, 4500); 
   }, 
   beforeRouteLeave (to, from, next) {
      document.querySelector('.circle').classList.remove('hoverProject')
      document.querySelector('.circle .wrapp').style.backgroundColor = "#ddd"
      if(to.params.nextProject == true){
         this.DestroyLocoScroll()
         next()
      } else {
         this.$store.dispatch('goLoader', true)
         this.DestroyLocoScroll()
         setTimeout(() => {
            next()
         }, 500);
      }
      
      const triggers = ScrollTrigger.getAll();
      triggers.forEach((trigger) => {
         trigger.kill();
      })
   },
   methods: {
      gsapAnimationTop(){
         const pageContainer = document.querySelector('#app');    
         const clientHeight = document.documentElement.clientHeight      
         
         // DESCRIPTION
         gsap.to('.concept .up_word span', {
         scrollTrigger: {scroller: pageContainer, trigger: '#description .concept .title', toggleActions: 'play play play reverse', start: `center bottom`},
         transform: 'translateX(0)', duration: 0.3, stagger: 0.2,ease: 'easeIn'})
         gsap.to('.concept .resume', {
         scrollTrigger: {scroller: pageContainer, trigger: '#description .concept .resume', toggleActions: 'play play play reverse', start: `center bottom`},
         transform: 'translateX(0)', opacity:1,  duration: 0.5, ease: 'easeIn'})   
         gsap.to('#description .details', {
         scrollTrigger: {scroller: pageContainer, trigger: '#description .details', toggleActions: 'play play play reverse', start: `center bottom`},
         transform: 'translateX(0)', opacity:1,  duration: 0.5, ease: 'easeIn'})  
         gsap.to('#description .link .wrapp', {
         scrollTrigger: {scroller: pageContainer, trigger: '#description .details', toggleActions: 'play play play reverse', start: `center bottom`},
            opacity:1,  duration: 0.5, ease: 'easeIn'});   
      
      },
      gsapAnimationVisuel(){

         const pageContainer = document.querySelector('#app'); 

        // Scroll horizontal
         if(document.querySelector(".pin-wrap")){
               let pinWrap = document.querySelector(".pin-wrap");
               let pinWrapWidth = pinWrap.offsetWidth;
               let pinWrapWidthFinal = pinWrapWidth / 1.5;
               let horizontalScrollLength = pinWrapWidth - window.innerWidth;
   
               gsap.to(".pin-wrap", {
                  scrollTrigger: {
                     scroller: pageContainer,
                     scrub: true,
                     trigger: "#sectionPin",
                     pin: true,
                     scrub: 0.7,
                     start: "top top",
                     end: 'bottom+='+pinWrapWidthFinal + ' bottom'
                  },
                  x: -horizontalScrollLength,
               }); 
         }
         ScrollTrigger.refresh();

         gsap.to(document.querySelectorAll('.pin-wrap img.first'), {
            scrollTrigger: {scroller: pageContainer, trigger: document.querySelectorAll('#sectionPin'), start: `top center`, toggleActions: 'play play play reverse'},
            transform: 'translateX(0)', opacity:1, duration: 0.8, ease: 'easeIn'         
         });   
            
      },
      DestroyLocoScroll(){
         this.destroyLocoScroll = true
      },
     
      goBack(){
         this.$store.dispatch('goLoader', true)
       
         this.$nextTick( () => {
            var navigate = this.$router;
            navigate.push({ name: 'Home' })
         })
      }, 
   },
}
</script>
<style scoped>
   #projet #sectionPin{
      background: #d20915;
   }
</style>