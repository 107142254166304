<template>
   <div class="circle">
      <div class="wrapp">
         <img class="play" src="@/assets/svg/play.svg">
         <img class="pause" src="@/assets/svg/pause.svg">
         <img class="arrow" src="@/assets/svg/go-arrow.svg">
      </div>
    </div>
</template>
 
<script>

export default {
    name: 'CursorCirle',
    data() {
        return {
        }
    },
    mounted () {
    }, 
    methods: {
   },
}
</script>

<style scoped lang="scss">
// @import '@/assets/scss/global.scss';
   
.circle {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 1000;
  &.load .wrapp{
    height:26px;
    width:26px;
  }
  .wrapp{
    background:#ddd;
    height:0;
    width:0;
    transform:translateX(-13px) translateY(-13px);
    border-radius: 100%;
    transition-duration: 0.3s;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .arrow{
      width:30px;
      transition-delay: 0s;
      transform-origin: center;
      transform:translateY(60px) translateX(-60px);
      filter:invert(1);
      position:absolute;
    } 
    .play{
      width:25px;
      transform-origin: center;
      transition-delay: 0s;
      transform: translateY(60px) translateX(-60px);
      position:absolute;
    } 
    .pause{
      width:30px;
      transform-origin: center;
      transition-delay: 0s;
      filter:invert(1);
      transform: translateY(60px) translateX(-60px);
      position:absolute;
    } 
  }


  &.hoverElement{
    .wrapp{
      width:80px;
      height:80px;
      transform:translateX(-40px) translateY(-40px);
      opacity: 0.25;
    }
  }


  &.hoverProject{
    .wrapp{
      width:120px;
      height:120px;
      transform:translateX(-60px) translateY(-60px);
      .arrow{
        transition-duration: 0.3s;
        transition-timing-function: ease-in;
        transition-delay: 0.1s;
        transform:translateY(0) translateX(0);
        filter:invert(1);
      } 
    }
  }


  &.hoverVideo{
    
    .wrapp{
      width:120px;
      height:120px;
      transform:translateX(-60px) translateY(-60px);
      .play{
        transition-duration: 0.3s;
        transition-timing-function: ease-in;
        transition-delay: 0.1s;
        transform:translateY(0) translateX(0);
      } 
    }
    &.pause{
      .pause{
        transition-duration: 0.3s;
        transition-timing-function: ease-in;
        transition-delay: 0.1s;
        transform:translateY(0) translateX(0);
      } 
      .play{
        transition-duration: 0.2s!important;
        transition-delay: 0s!important;
        transform: translateY(100px) translateX(-100px)!important;
      } 
    }
  }
} 
@media screen and (max-width: 1280px) {
  .circle.hoverVideo .wrapp{width:100px; height:100px;}
  .circle.hoverProject .wrapp{width:100px; height:100px;}
}
@media screen and (max-width: 980px) {
  .circle{display:none}
}
</style>