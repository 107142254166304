<template>
    <div ref="project" :class="' project ' + this.side" :id="'project' + this.projectId" :data-color="this.color" :data-route="this.route">
          
         <a class="wrapp_image hoverElement hover_project"  data-scroll="" data-scroll-delay="0.03" :data-scroll-speed="0.8" :data-color="this.color">
            <div class="polygon">
               <div class="wrapper">
                  <div :class="'wrapp_background ' + this.imgPosition">
                     <div class="wrapp_scale">
                        <img :src="require(`@/assets/img/${mainImage}`)" data-scroll="" data-scroll-delay="0.03" data-scroll-speed="0.6">
                     </div>
                  </div>
                  <div class="background_color" :style="{backgroundColor:`${this.color}`}"></div>
               </div>
            </div>
         </a>
         <div class="description hoverElement hover_project" :data-color="this.color" data-scroll="" data-scroll-delay="0.04" :data-scroll-speed="(windowWidth < 780) ? -0.1 : -1.5" >
               <a><h3 class="title up_word" ><span>{{this.projectName}}</span></h3></a>
               <div class="details">
                  <div class="categorie" :style="{color:`${this.color}`}">
                     <p v-for="item in features" :key="item.message">
                           {{ item }}
                     </p>
                  </div>
               </div>
         </div>
         <!-- <div class="overlay_project" ></div> -->
    </div>
</template>
 
<script>
import { TimelineLite, gsap  } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"

export default {
    name: 'Projet',
    props: {
      features: {type: Array, require: true},
      urlImage: {type: String, require: true},
      urlImageMobile: {type: String, require: true},
      projectName: {type: String, require: true},
      side: {type: String, require: true},
      projectId: {type: String, require: true},
      color: {type: String, require: true},
      imgPosition: {type: String, require: true},
      route: {type: String, require: true},
    },
    data() {
        return {
           windowWidth: window.innerWidth,
           mainImage: ''
        }
    },
    beforeMount() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
         this.mainImage = this.urlImageMobile
      } else {
         this.mainImage = this.urlImage
      }
   },
}
</script>

<style scoped lang="scss">
.project{
      position: relative;
      margin-top: 12vh;
      margin-bottom: 16vh;
      display: flex;      
      height: 70vh;
      -webkit-tap-highlight-color: transparent;
    
     
      // .overlay_project{
      //    opacity:0;
      //    top:0;
      //    left:0;
      //    background:#151515;
      // }
      &.noScale{
         z-index: 150;
         .wrapp_image{
            z-index: 10;
            
         }
         .wrapp_image::after{
            opacity:0!important
         }
         .wrapp_scale{
            transition-duration: 0.5s;
            transform:scale(1)!important;
         }
         .description {
            opacity: 0;
            transition-duration: 0.3s;
         }
      }
      &:hover{
         .polygon{
            transition-duration: 0.45s;
            clip-path: polygon(2% 2%, 98% 2%, 98% 98%, 2% 98%)!important
         }
         .wrapp_image::after{
            opacity:0;
         }
      }
      .wrapp_image{
         width:50%;
         height: 70vh;
         overflow: hidden;
         display: block;
         position:fixed;
         z-index: 100;
         cursor: pointer;
         .polygon{
            height: 100%;
            width: 100%;
            display: block;
            transition-duration: 0.45s;
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%); 
            &.noPolygon{
               clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)!important ;
            }
         }
         .wrapper{
            position: absolute;
            height: 0%;
            width: 100%;
            left: 0;
            bottom: 0;
            overflow: hidden;
            .background_color{
               position:absolute;
               top:0;
               left:0;
               width:100%;
               height:0%;
            }  
         }
         &::after{
            transition-duration: 0.5s;
            content:'';
            // background:rgba(0,0,0,0.1);
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
         }
         .wrapp_background{
            transform: scale(1.12);
            transform-origin: center;
            height: 100%;
            width: 100%;
            &.left{
               transform-origin: left;
            }
            &.center{
               transform-origin: center;
            }
            &.right{
               transform-origin: right;
            }
            .wrapp_scale{
               transition-duration: 0.5s;
               height: 100%;
               width: 100%;
               img{
                  width:100%;
                  position: relative; 
                  height: 100%;
                  object-fit: cover;
               }
            }
         }
      } 
      .description{
         width:51%;
         cursor: pointer;
         z-index: 101;
         position: absolute;
         top: 0;
         right: 0;
         text-align: left;
         transform: translateY(-50%);
         top: 35%;
         &::after{
            content:'';
            position:absolute;
            z-index:120;
            width:100%;
            height:100%;
            top:0;
            left:0;
            -webkit-tap-highlight-color: transparent;
         }
         .up_word > * {transform: translateY(-110%);}
         .title{
            color:#fff;
            font-size:7vw;
            margin: 0;
            line-height: 1.15;
            display: block;
            -webkit-text-stroke: 2px #fff;
            color: rgba(255, 255, 255, 0);
            transition-duration: 0.5s;
         }
         .details{
            width: 70%;
            float: right;
            transform: translateY(50%);
            opacity: 0;
            margin-top:10px;
            .categorie{
               font-weight: 600;
               line-height: 0.7;
               font-size: 16px;
               text-transform: uppercase;
               // display: flex;
               p{
                  margin-right: 18px;
               }
            }
            .resume{
               margin-top:15px;
               max-width: 50%;
               font-size:16px;
               line-height: 1.4;;
               color: rgba(255, 255, 255, 0.4);
               opacity: 0;
               transform:translateY(40%);
            }
         }
      }
      &.left{
      }
      &.right{
         justify-content: end;
         .wrapp_image {
            margin-left:auto;
         }
         .description{
            text-align: right;
            right:auto;
            left:0;
            .details{
               float:left;
               .categorie{
                  justify-content: end;
                  p{
                     margin-right:0;
                     margin-left:18px
                  }
               }
               .resume{
                  float:right;
               }
            }
         }
      }
   }
@keyframes polygon {
   0% { clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%) }
   100% { clip-path: polygon(30% 10%, 70% 10%, 70% 90%, 30% 90%) }
}
@media screen and (max-width: 1780px) {
}
@media screen and (max-width: 1480px) {
}
@media screen and (max-width: 1380px) {
}
@media screen and (max-width: 1281px) {
   .project .description .details .categorie{display:block}
   .project .description .details .categorie p{margin-left:0!important; margin-right:00!important}
}
@media screen and (max-width: 980px) {
}
@media screen and (max-width: 980px) and (min-height: 880px) {
   .project .wrapp_image { height: 35vh;  }
   .project .wrapp_image {width: 60%;height: 35vh; }
   .project .description{width: 70%; top: 30%;}
   .project.left .description{text-align: right;}
   .project.right .description{text-align: left;}
   .project.left .description .details{text-align: left;}
   .project.right .description .details{text-align: right;}
   .project .description  .title{font-size: 9vw;}
   .project .description .details {width: 40%;}
}
@media screen and (max-width: 580px) {
   .project {margin-top: 10vh; height: 45vh; margin-bottom: 10vh;}
   .project .wrapp_image {width:60%; height: 45vh; }
   .project .description {width:90%}
   .project .description .title {font-size: 13vw;}
   .project .description .title {-webkit-text-stroke: 1px #fff;}
   .project .description .details .categorie {font-size: 12px;}
   .project.left .wrapp_image {margin-left:-30px}
   .project.right .wrapp_image {margin-right:-30px}
   .project.left .description, .project.right .title{text-align: left;}
   .project.right .description, .project.left .title{text-align: right;}
   .project.left .description .details{text-align: right;}
   .project.right .description .details{text-align: left;}
}
</style>