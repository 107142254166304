<template>
   <div id="wrapp_top" class="relative">
      <div id="intro" class="container">
         <div class="overflow_block" ref="box">
            <!-- Text black -->
            <h2 class="intro_message" ref="intro" >
               <span class="overhidden">
                  <span class="line">
                     {{ firstLine }}
                  </span>
               </span>
               <span class="overhidden">
                  <span class="line wrapp_word">
                     et
                     <span class="change_color">
                        <span class="firstTime opa" ref="first">
                        <span class="change_word first " >{{ secondWord }}</span>
                        </span>
                        <span class="change_word second">{{ firstWord }}</span>
                     </span>
                  </span>
               </span>
               <img class="front" src="@/assets/svg/front.svg">
            </h2>

            <!-- Image container -->
            <div class="img_container" ref="imgContainer">
               <div class="background" ref="background"></div>
               <div class="wrapper" ref="wrappPhoto">
                  <img ref="photo" src="@/assets/img/sylvain3.jpg">
               </div>
            </div>
         </div>

         <!-- Arrow -->
         <div class="scroll_bottom scrollToBtn hover_element" data-href="#about">
            <svg height="200" width="200">
               <circle class="circle" cx="100" cy="100" r="95" stroke="#bbb" stroke-width="2" fill-opacity="0" />
            </svg>
            <div class="background"></div>
            <div class="wrapp_arrow">
               <img class="arrow" src="@/assets/svg/arrow.svg">
            </div>
         </div>

      </div>
   
      <!-- Background -->
      <div class="background_overlay" ref="backgroundOverlay"></div>
   </div>
</template>

<script>
import { TimelineLite, gsap  } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
   name: 'Intro',
   props: {
      firstLine: String,
      firstWord: String,
      secondWord: String
   },
   data () {
      return {
      }
   },
   mounted () {
      // init gsapAnimation
      setTimeout(() => { this.gsapAnimation() }, 100);
   },
   methods: {
      gsapAnimation(){
            const { box, imgContainer, background, photo, intro, first, wrappPhoto, backgroundOverlay } = this.$refs
            const pageContainer = document.querySelector('#app');

            // Animation on load
            function introAnimation(){
               if(window.innerWidth > 980){
                  var tl1 = new TimelineLite()
                  tl1.to(box, { duration: 1.6, width: '100%', delay: 0.1 })
                  .to(imgContainer, { duration: 2, width: '100%' }, '-=2')
                  .to(background, { duration: 0.4, width: '100%', ease: 'easeOut' }, '-=0.2' )
                  .to(background, { duration: 0.4, width: '0%', ease: 'easeIn' })
                  .to(photo, { duration: 0.4, display: 'block', transform: 'translateX(0%)', ease: 'easeIn' }, '-=0.5')
                  .add( function(){ 
                     imgContainer.classList.add('absolute')
                     document.querySelector('.scroll_bottom').classList.add('load')
                     if(document.querySelector('#nav')){
                        document.querySelector('#nav').classList.add('load')
                     }
                     intro.classList.add('load')
                  }, '-=1' )
                  .add( function(){ 
                        first.classList.remove('opa')
                  }, '+=0.8' )
               } else {
                  var tl1 = new TimelineLite()
                  tl1.to(box, { duration: 1.1, height: '100%', delay: 0.2 })
                  .to(imgContainer, { duration: 1.1,  height: '55vh', top:'33%' }, '-=4')
                  .to(background, { duration: 0.4, height: '100%', ease: 'easeOut' }, '-=0.5')
                  .add( function(){ 
                     imgContainer.classList.add('absolute')
                     if(document.querySelector('#nav')){
                        document.querySelector('#nav').classList.add('load')
                     }
                  }, '+=0.3' )
                  .to(background, { duration: 0.4, height: '0%', ease: 'easeIn' })
                  .to(photo, { duration: 0.5, display: 'block', transform: 'translateX(0%)', ease: 'easeIn' }, '-=0.5')
                  .to(backgroundOverlay, { duration: 0.5, marginBottom: '0', ease: 'easeIn' }, '-=0.5')
                  .add( function(){ 
                     intro.classList.add('load') 
                  }, '-=1' )
                  .add( function(){ 
                     first.classList.remove('opa')
                  }, '+=0.8' )
               }
            }
            introAnimation()

            function isMobile() {
               return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            }
            window.addEventListener('resize', function(){ 
               if(!isMobile()){
                  introAnimation()
               }
            });

            // // Animation on Scroll
            var clientHeight = document.documentElement.clientHeight
            if(window.innerWidth > 980){
               gsap.to(imgContainer, {scrollTrigger: {scroller: pageContainer, trigger: '#intro', start: `top`, end: `bottom center`, scrub: true,}, marginTop: '10%'})  
            } else {
               gsap.to(imgContainer, {scrollTrigger: {scroller: pageContainer, trigger: '#intro', start: `top`, end: `bottom center`, scrub: true,}, marginTop: '15%'})  
            }
            gsap.to(wrappPhoto, {
               scrollTrigger: {scroller: pageContainer, trigger: '#intro', start: `top`, end: `bottom top`, scrub: true},
               marginTop: '30%', 
            }) 
            gsap.to('#wrapp_top .background_overlay', {
               scrollTrigger: {scroller: pageContainer, trigger: '#intro', start: `top`, end: `bottom+=200 top`, scrub: true},
               height: '100%', 
            }) 
      },
      scrollToAbout(){
         locoScroll.scrollTo('#about');
         navigation.reversed() ? navigation.play() : navigation.reverse();
      }
   },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/global.scss';
.front{
   position: absolute;
   top: 5%;
   width: 7vw;
   left: 35vw;
   transform: rotate(-15deg);
}
.relative{
   position:relative
}
.background_overlay{
   width:100%;
   background:$base-color;
   height:0%;
   position:absolute;
   bottom: -2px;
   z-index: 50;
}
.scroll_bottom{
   position: absolute;
   left:-18px;
   bottom: 2%;
   transform: scale(0.8);
   cursor:pointer;
   &::after{
      content:'';
      position:absolute;
      z-index: 10;
      height:100%;
      width:100%;
      left:0;
      top:0;
      border-radius: 100%;
   }
   .wrapp_arrow{
      opacity: 0;
      transition-duration: 0.6s;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-65%);
      position: absolute;
      .arrow{
         width: 80px;
         transition-duration: 0.3s;
      }
   }
   .background{
      height: 0;
      width: 0;
      border-radius: 100%;
      background: $base-color;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      transition-duration: 0.4s;
   }
   &.load .wrapp_arrow{
      opacity: 1;
      transition-delay: 0.8s;
      transform: translateX(-50%) translateY(-50%);
   }
   .circle {
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
   }
   &.load .circle {
      animation: stroke 1s cubic-bezier(.87,.01,.83,.67) forwards;
   }
   &:hover{
      .background{
         height:97%;
         width:97%;
      }
      .arrow{
         filter:invert(1);
         transition-duration: 0.3s;
         transform: translateY(10%);
      }
   }
}
@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}


#wrapp_top{
   background:#fff;
   #intro{
      height: 100vh;
      h1 {
         font-size: 10vw;
         margin: 0;
         mix-blend-mode: difference;
      }
      .overflow_block{
         width:0%;
         overflow: hidden;
         position: relative;
         display: flex;
         align-items: center;
         height:100%;
      }
      .intro_message{
         font-size: 6vw;
         transition-delay: 0s!important;
         text-align: left;
         font-weight: 800;
         color: #eee;
         margin: 0 0 0 5%;
         width: 100%;
         min-width: 1050px;
         display: block;
         position: relative;
         z-index: 2;
         mix-blend-mode: difference;
         line-height: 1.2;
         .line{
            display: block;
            overflow: hidden;
            position:relative;
            .text{
            display: block;
            }
         }
         .change_word{
            position: absolute;
            width: 100%;
            overflow: hidden;
            margin-left:2vw;
            font-size: 6vw;
            transition-delay: 0s!important;
         }
         .change_color{
            color: #eee;
            transition-duration: 0s;
            transition-delay: 1.9s;
            .opa{
               opacity: 0!important;
               transform:translateY(-200%)
               // display: block;
            }
         }
         &.load .change_word.first{
            transform:translateY(100%);
            opacity: 0;
            animation: rotateWord 2.6s cubic-bezier(.87,.01,.83,.67) infinite 0s;
         }
         &.load .change_word.second{
            transform:translateY(0%);
            animation: rotateWord 2.6s cubic-bezier(.87,.01,.83,.67) infinite 1.3s;
         }
         &.load .change_color{
            color: $base-color-invert;
         }
      }
      .img_container{
         height: 40vw;
         max-height: 75vh;
         width: 0%;
         max-width: 32%;
         background: #111;
         position: absolute;
         top: 50%;
         transform: translateY(-50%);
         right: 0;
         overflow: hidden;
         &.absolute{
            position: absolute;
            z-index: 55;
         }
         .background{
            height:100%;
            width:0%;
            background:$base-background;
            position:absolute;
            left:0;
            z-index: 10;
         }
         .wrapper{
            transform:scale(1.18);
            transform-origin: right bottom;
            padding-top: 55px;
         }
         img{
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: none;
            transform:translateX(20%);
            image-rendering: -webkit-optimize-contrast;
            
            
         }
      }
   }
}
@media screen and (min-width: 980px) {
   #wrapp_top #intro .img_container{top:50%!important}
}
@media screen and (max-width: 1630px) {
   #wrapp_top #intro .img_container {height: 45vw; max-width: 34%;}
}
@media screen and (max-width: 1530px) {
   #wrapp_top #intro .img_container {height: 45vw; max-width: 36%;}
}
@media screen and (max-width: 1430px) {
   #wrapp_top #intro .overflow_block {margin-left: 0; margin-right:0}
   .scroll_bottom {transform: scale(0.7); left: -30px;}
   #wrapp_top #intro .intro_message{margin-left:2%}
}
@media screen and (max-width: 1130px) {
   .scroll_bottom {transform: scale(0.65); left: -35px;}
   #wrapp_top #intro .img_container {height: 49vw; max-width: 39%;}
}
@media screen and (max-width: 980px) {
   .front {top: -2%;width: 13vw;left: 50vw;}
   #wrapp_top #intro .overflow_block{display: block;}
   #wrapp_top #intro .intro_message{top: 12%; font-size: 9vw; margin-left: 0}
   #wrapp_top #intro .intro_message .change_word{font-size: 9vw;}
   .scroll_bottom {transform: scale(0.5); left:-15%; bottom: -20px;}
   .scroll_bottom {left: 50%; bottom: -35px; transform: translateX(-50%) scale(0.45);}
   #wrapp_top #intro .overflow_block {width: 100%!important; height:0%;}
   #wrapp_top #intro .img_container .wrapper{transform: scale(1.08);}
   #wrapp_top #intro .img_container{max-width: 100%; position:absolute; top: 57%;  width:100%!important; height:0; transform: translateY(0);}
   #wrapp_top #intro .img_container .background{width:100%!important; height:0;}
   #wrapp_top #intro .img_container img{transform: translateY(20%); margin-top: -40%;}
   .background_overlay {margin-bottom: -60%; height:25%}
   .background_overlay.load {margin-bottom:0;}
}
@media screen and (max-width: 780px) {
   .front {top: 0%;width: 15vw;left: 61vw;}
   #wrapp_top #intro .intro_message{top: 15%; font-size: 11vw; margin-left: 0}
   #wrapp_top #intro .intro_message .change_word{font-size: 11vw;}
   .scroll_bottom {transform: translateX(-50%) scale(0.4); bottom: -45px;}
   #wrapp_top #intro .img_container img{margin-top: -20%;}
}
@media screen and (max-width: 580px) {
   #wrapp_top #intro .img_container .wrapper{transform: scale(1.18);}
}
@media screen and (max-width: 580px) and (min-height: 740px) {
   #wrapp_top #intro .img_container img{margin-top: -9%}
}
@media screen and (max-width: 580px) and (min-height: 840px) {
   #wrapp_top #intro .img_container img{margin-top: -7%}
}

 

@keyframes rotateWord {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  25% {
    opacity: 1;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  26% {
    opacity:0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  30% {
    opacity:0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  49% {
    opacity:0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  50% {
    opacity: 1;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  } 
  75% {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  } 
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  } 
}
 

</style>
