<template>
    <div id="wrapp_loader">
       <div id="loader" v-if="goLoader" :style="{backgroundColor:`${this.$store.state.color}`}"></div>
    </div>
</template>

<script>
export default {
   name: 'Loader', 
   data() {
      return {
         goLoader: this.$store.state.goLoader
      }
   },
   watch: {
      '$store.state.goLoader': function() {
         this.goLoader = this.$store.state.goLoader
         setTimeout(() => {
            this.$store.dispatch('goLoader', false)
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
               document.querySelector("meta[name=theme-color]").setAttribute("content", '#151515');
            }
         }, 1700);
      }
   }
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/global.scss';
#loader{
   height:100vh;
   width:0vw;
   background:#000;
   position:fixed;
   top:0;
   left:auto;
   right:0;
   z-index: 1000;
   animation: upWord 1.7s cubic-bezier(.87,.01,.83,.67) forwards 0s;
}
@keyframes upWord {
   0% {
      width:0vw;
      left:auto;
      right:0;
   }
   30% {
      width:100vw;
      left:auto;
      right:0;
   } 
   60% {
      width:100vw;
      left:0;
      right:auto;
   } 
   100% {
      width:0vw;
      left:0;
      right:auto;
   } 
}
</style>