<template>
    <div :class="'video noload ' + this.side +' '+ this.classMobile" ref="wrappVisuel" @mouseleave="handleLeave" >
        <div class="text" ref="text" data-scroll="" data-scroll-delay="0.1" :data-scroll-speed="(windowWidth < 980) ? 0 : 3">
            <div class="wrapp">
                <div class="title"><slot name="title"></slot></div>
                <div class="content"><slot name="content"></slot></div>
            </div>
        </div>
        <div 
            @click="videoPlay = !videoPlay; loadVideo(require(`@/assets/img/${videoName}`))"
            class="wrapp_image hover_video" 
            ref="wrappImage" 
            :data-color="this.color" 
            :style="{backgroundColor:`${this.secondColor}`}"
            data-scroll="" 
            data-scroll-delay="0.1" 
            :data-scroll-speed="(windowWidth < 980) ? 0 : this.scrollSpeed"
        >
            <div :class="'wrapp_play ' + this.videoPlay" >
                <div class="play" data-scroll="" data-scroll-delay="0.1" data-scroll-speed="0.3">
                    <img class="item" src="@/assets/svg/play.svg">
                </div>
            </div>
            <video muted playsinline loop ref="video" :poster="require(`@/assets/img/${poster}`)">
                <source ref="source" type="video/mp4">
            </video> 
        </div>
    </div>
</template>
 
<script>
import { TimelineLite, gsap  } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"

export default {
    name: 'Video',
    props: {
      scrollSpeed: {type: Number, require: true},
      videoName: {type: String, require: true},
      poster: {type: String, require: true},
      side: {type: String, require: true},
      color: {type: String, require: true},
      secondColor: {type: String, require: true},
      mobile: {type: Boolean, require: false, default: false},
    },
    data() {
        return {
            videoPlay: false,
            windowWidth: window.innerWidth,
            classMobile: (this.mobile == true) ? 'mobile' : 'desktop',
        }
    },
    mounted () {
        setTimeout(() => {
            this.gsapAnimation()
        }, 300);
    }, 
    methods: {
        gsapAnimation(){
            const pageContainer = document.querySelector('#app'); 
            const { wrappVisuel, wrappImage, video, text } = this.$refs

            // Visual FadeIn 
            gsap.to(wrappImage, {
                scrollTrigger: {scroller: pageContainer, trigger: wrappVisuel, start: `top+=35% bottom`, toggleActions: 'play play play reverse'},
                clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', opacity:1, duration: 0.8, ease: 'easeIn',  
            });   
            if(this.windowWidth < 980){
                gsap.from(text, {
                    scrollTrigger: {scroller: pageContainer, trigger: text, start: `top+=55% bottom`, toggleActions: 'play play play reverse'},
                    transform: 'translateY(15%)', opacity:0, duration: 0.6, ease: 'easeIn'         
                });   
            }
                  
        },
        loadVideo(id){
            const {video, source, wrappVisuel } = this.$refs
            if( wrappVisuel.classList.contains('noload') ){
                source.src = id;
                video.load();
                video.play();
                wrappVisuel.classList.remove('noload')
            }  
        },
        // stopPause(){
        //     const circle = document.querySelector(".circle");
        //     circle.classList.remove('pause')
        // },
        handleLeave(){
            const { video } = this.$refs;
            this.videoPlay = false
            video.pause()
        }
   },
    watch: {
        videoPlay: function(newVal){
            const { video } = this.$refs;
            const circle = document.querySelector(".circle");
            if (newVal === true){
                video.play()
                circle.classList.add('pause')
            } else{
                video.pause()
                circle.classList.remove('pause')
            }
        },
    }
}
</script>

<style scoped lang="scss">
// @import '@/assets/scss/global.scss';
.video{
    position: relative;
    padding-top:6vh;  
    padding-bottom:6vh; 
    .text{
        position: absolute;
        left:auto;
        width: 25%;
        right:0;
        top:30%;
        text-align: left;
        &.is-inview .wrapp{
            opacity: 1;
        }
        .wrapp{
            opacity: 0;
            transition-duration:1.2s;
        }
        .title{
            font-size: 40px;
            font-weight: 800;
            color: #fff;
            line-height: 1.15;
            margin-bottom: 20px;
        }
        .content{
            color:#777;
            font-size: 18px;
            line-height: 1.5;
        }
    } 
    .wrapp_image{
        cursor:pointer;
        img,video{
            display: block;
        }
        &::after{
            content:'';
            position:absolute;
            left:0;
            top:0;
            width: 100%;
            height:100%;
            // background:rgba(0,0,0,0.2)
        }
        &:hover{
            .wrapp_play{
                opacity:0;
            }
        }
    }
    .wrapp_play{
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        transition-duration: 0.4s;
        position: absolute;
        &.true{
            display: none;
        }
        .play{
            z-index: 10;
            width: 70px;
            height: 70px;
            //    background: rgba(0,0,0,0.5);
            background: rgba(0, 0, 0, 0.28);
            padding: 50px;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            //  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
            img{
                width:55px;
                margin:0 -8px -8px 0;
            }
        }
    }
    .wrapp_image{
        width:70%;
        overflow: hidden;
        display: block;
        opacity: 0;
        position:relative;
        clip-path: polygon(30% 10%, 70% 10%, 70% 90%, 30% 90%); 
        
        video{
            width: 100%;
        }
        .blank{
            width:100%;
            position: relative; 
            height: 100%;
        }
        .item{
            width:100%;
        }
    }
    &.right{
        .text{
            left: 0;
            right:auto;
            text-align: right;
        }
        .wrapp_image{
            margin-left:30%
        }
    }
    &.mobile{
        .wrapp_image video{
            padding: 20px 0;
            max-height: 80vh;
            &::after{
                content:'';
                height:100%;
                width:3px;
                position:absolute;
                right:0;
                background:red;
            }
        }
    }
}

@media screen and (max-width: 1480px) {
    .video .text .title{font-size:35px;}
    .video .text .content{font-size:17px;}
    .video .text {top: 15%;}
    .video .wrapp_play .play {width: 55px; height: 55px;}
    .video.mobile .wrapp_image video {max-height: 75vh;}
}
@media screen and (max-width: 1280px) {
    .video .text .title{font-size:28px;}
    .video .text .content{font-size:16px;}
    .video .text {top: 15%;}
}
@media screen and (max-width: 980px) {
    .video .wrapp_image{width: 100% !important; }
    .video .text{position:relative; width: 100%;}
    .video {display: flex; flex-wrap: wrap; flex-direction: column-reverse;}
    .video.right .wrapp_image{width: 100% !important; margin-left: 0 !important;}
    .video .wrapp_play{opacity: 1!important;}
    .video .wrapp_play .play {width: 50px; height: 50px;}
}
@media screen and (max-width: 580px) {
    .video .text .title {font-size: 23px;}
    .video .text .content {font-size: 15px;margin-top: -10px;}
    .video .wrapp_play .play img {width: 45px;}
    .video .wrapp_play .play {width: 25px; height: 25px;}
    .video.mobile .wrapp_image video {max-height: 70vh;}
}
</style>