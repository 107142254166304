import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

export default new Vuex.Store({
   state: {
      currentProjectId: 0,
      color: null,
      goLoader: false,
   },

   mutations: {
      UPDATE_ID(state, amount) {
         state.currentProjectId = amount
      },
      UPDATE_COLOR(state, color) {
         state.color = color
      },
      GO_LOADER(state, bool) {
         state.goLoader = bool
      },
   },

   actions: {
      updateProjectId(context, amount) {
         context.commit('UPDATE_ID', amount)
      },
      updateColor(context, color) {
         context.commit('UPDATE_COLOR', color)
      },
      goLoader(context, bool) {
         context.commit('GO_LOADER', bool)
      },
   }
})
