<template>
</template>

<script>
import { TimelineLite, gsap, TweenMax  } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import LocomotiveScroll from 'locomotive-scroll';
import CursorCirle from '@/components/CursorCirle.vue'
export default {
   name: 'LocoScroll',
   components: {
      CursorCirle
   },
   props: {
      destroyBool: {type: Boolean, require: true},
      scrollToProjectId: {type: Number, require: true}
   },
   data() {
      return {
         locoScroll: null,
      }
   },
   mounted () {
      this.initLocomotive()
      this.scrollToOnClick()
      this.scrollToProjectID()
      if(!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
         this.initCustomCursor()
      }
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
         setTimeout(() => {
            this.changeThemeColor()
         }, 1200);
      }
   },
   methods: {
      initLocomotive(){
         
         if (this.locoScroll !== null) {
            this.locoScroll.stop();
            this.locoScroll.destroy();
         }
         
         gsap.registerPlugin(ScrollTrigger)
         const pageContainer = document.querySelector('#app');
         
         var these = this;
         var scroller = null;

         this.locoScroll = scroller = new LocomotiveScroll({
            el: pageContainer,
            smooth: true,
            multiplier: 0.7,
            touchMultiplier: 2,
            reloadOnContextChange: true,
            smartphone: {
               smooth: 1,
               breakpoint: 767
            },
            tablet: {
               smooth: 1,
               breakpoint: 1024
            },
            getSpeed: true,
            getDirection: true,
         })
         
         ScrollTrigger.scrollerProxy(pageContainer, {
            scrollTop(value) {
               these.$emit('receive-positionY', scroller.scroll.instance.scroll.y)
               return arguments.length ? scroller.scrollTo(value, 0, 0) : scroller.scroll.instance.scroll.y;
            }, 
            getBoundingClientRect() {return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};}
         }); 
         this.locoScroll.on("scroll", ScrollTrigger.update);
         ScrollTrigger.addEventListener("refresh", () => scroller.update());
         ScrollTrigger.refresh();
 
      },
      scrollToProjectID(){
         if (this.$store.state.currentProjectId != 0){ 
            const target = document.querySelector('#project'+this.$store.state.currentProjectId);
            this.locoScroll.scrollTo(target, {duration: 220, offset: -200});
         } else {
            // Pause Scroll on loading page
            this.locoScroll.stop()
            setTimeout(() => {
               this.locoScroll.start()
            }, 2200);
         }
      },
      scrollToOnClick(){
         var scroller = this.locoScroll
         var buttonScrollTo = document.querySelectorAll('.scrollToBtn');
         buttonScrollTo.forEach(function(btn){
            btn.addEventListener('click', function(e){
               e.preventDefault();
               let target = e.target.getAttribute("data-href")
               scroller.scrollTo(target, {duration: 800});
            });
         })
      },
      initCustomCursor(){
         const circle = document.querySelector(".circle");
         const pageContainer = document.querySelector('#app');
         container.addEventListener("mousemove", function(e) {
            if(!circle.classList.contains('load')){
               setTimeout(() => {circle.classList.add('load')}, 350);
            }
            var relY = e.pageY;
            var relX = e.pageX - pageContainer.offsetLeft;
            TweenMax.to(circle, 0.4, { x: relX, y: relY });
         });
         this.locoScroll.on('scroll', (instance) => {
            var positionScrollY = instance.scroll.y
            TweenMax.to(circle, 0, { top: positionScrollY +'px' });
         });

         // HOVER PROJECT  
         const hoverProject = document.querySelectorAll('.hover_project');
         hoverProject.forEach(item => {
            item.addEventListener('mouseover', (e) => {
               console.log('fuck')
               circle.classList.add('hoverProject')
               circle.querySelector('.wrapp').style.backgroundColor = e.target.dataset.color
            })
            item.addEventListener('mouseleave', (e) => {
               circle.classList.remove('hoverProject')
               circle.querySelector('.wrapp').style.backgroundColor = '#ddd'
            })
            item.addEventListener('click', (e) => {
               circle.classList.remove('hoverProject')
               circle.querySelector('.wrapp').style.backgroundColor = "#ddd"
            })
         })

         // HOVER ELEMENT
         const hoverElement = document.querySelectorAll('.hover_element');
         hoverElement.forEach(item => {
            item.addEventListener('mouseover', (e) => {
               circle.classList.add('hoverElement')
            })
            item.addEventListener('mouseleave', (e) => {
               circle.classList.remove('hoverElement')
            })
            item.addEventListener('click', (e) => {
               circle.classList.remove('hoverElement')
            })
         })

      },     
      changeThemeColor(){
         function getOffset(el) {
            const rect = el.getBoundingClientRect();
            return rect.top 
         }
         this.locoScroll.on('scroll', () => {
            if( getOffset(document.querySelector('.background_overlay_P')) <= 0){
               document.querySelector("meta[name=theme-color]").setAttribute("content", '#111111');
            } else {
               document.querySelector("meta[name=theme-color]").setAttribute("content", '#ea3752');
            }
         });
      },
      destroyLoco(){
         this.locoScroll.stop();
         this.locoScroll.destroy()
      }
   },
   watch: {
      destroyBool: function(newVal){
         if (newVal === true){
            this.destroyLoco()
         }
      },
   }
}
</script>


 
