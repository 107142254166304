<template>
   <div id="home" ref="load_intro" v-if="renderComponent">
      <!-- LocoScroll Init -->
      <LocoScroll :destroyBool="this.destroyLocoScroll" @receive-positionY="updatePositionY" v-if="renderComponent"/>

      <!-- Menu -->
      <Menu/>

      <!-- Intro -->
      <Intro firstLine='Développeur' firstWord='créatif' secondWord='passionné'/>
      
      <!-- A propos -->
      <Apropos/>
      
      <!-- Projets -->
      <Projets @receive-click-project="goDestroyLocoScroll" :yPosition="yPosition"/>

      <!-- Footer -->
      <Contact/>
   </div>
</template>

<script>
import LocoScroll from '@/components/LocoScroll.vue'
import Menu from '@/components/Menu.vue'
import Intro from '@/components/Intro.vue'
import Apropos from '@/components/Apropos.vue'
import Projets from '@/components/Projets.vue'
import Contact from '@/components/Contact.vue'
export default {
   name: 'Home',
   components: {
      LocoScroll,
      Intro,
      Projets,
      Apropos,
      Contact,
      Menu
   },
   data() {
      return {
         renderComponent: true,
         destroyLocoScroll: false,
         yPosition: 0,
      }
   }, 
   
   mounted () { 
      setTimeout(() => {
         this.$store.dispatch('updateProjectId', 0)
      }, 1000);
   },   
   beforeRouteLeave (to, from, next) {
      if(to.params.goProject == true){
         this.goDestroyLocoScroll()
         next()
      } else {
         this.$store.dispatch('goLoader', true)
         this.goDestroyLocoScroll()
         setTimeout(() => {
            next()
         }, 500);
      }
 
   },
   methods: {
      updatePositionY(value){
         this.yPosition = value
      },
      goDestroyLocoScroll(){
         this.destroyLocoScroll = true
      },
      forceRerender(){
         this.renderComponent = false;
         this.$nextTick( () => {
            console.log('on rentre')
            setTimeout(() => {
               
               this.renderComponent = true;
            }, 2000);
         })
      }, 
   },
}
</script>

<style lang="scss">
@import '@/assets/scss/global.scss';
#home{
   background:$base-color;
}

</style>