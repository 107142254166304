<template>
   <div id="projets">
      <div class="wrapp_content">

         <!-- Big title -->
         <p id="direction2" >
            <span class="big_title" data-scroll="" data-scroll-direction="horizontal" :data-scroll-speed="(windowWidth < 780) ? -4 : -8" data-scroll-target="#direction2" >Projets <span>Projets</span> Projets <span>Projets</span></span>
         </p>

         <!-- Projects -->
         <div class="wrapp_projects">
            <Projet urlImage="sexbattle/main-image.jpg" urlImageMobile="sexbattle/main-image-mobile.jpg" side="left" projectId="1" projectName="Sex Battle" color="#843680" :features="['Illustration','Game Design','Développement']" imgPosition="right" route="Sexbattle"/>
            
            <Projet urlImage="dezyr/main-image.jpg" urlImageMobile="dezyr/main-image-mobile.jpg" side="right" projectId="2" projectName="Dezyr" color="#d77287" :features="['UX, UI Design','Intégration','Développement', 'SEO / Marketing']" imgPosition="right" route="Dezyr"/>
            
            <Projet urlImage="adrenaline/main-image.jpg" urlImageMobile="adrenaline/main-image-mobile.jpg" side="left" projectId="3" projectName="Adrenaline" color="#e20612" :features="['UX, UI Design','Intégration','Développement']" imgPosition="center" route="Adrenaline"/>
         </div>

         <div class="background_overlay"></div>
      </div>
   </div>
</template>

<script>
import { TimelineLite, gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Projet from '@/components/Projet.vue';
export default {
   name: 'Projets',
   components: {
      Projet,
   },
   props:{
      yPosition: {type: Number, default: true},  
   },
   data() {
      return {
         locoScroll: '', 
         projectId: undefined,
         animation: undefined,
         windowWidth: window.innerWidth
      }
   },
   mounted() {
      // Init GSAP animation
      setTimeout(() => { this.gsapAnimation() }, 300);
      // Init click projects
      this.clickProject()
   }, 
   methods: {
      clickProject(){
         const projects = document.querySelectorAll('.wrapp_projects .project');
   
         projects.forEach((project) => {
            var that = this;
            var navigate = this.$router;
 
            project.addEventListener('click', function(el) {

               this.classList.add('noScale')
               var tl1 = new TimelineLite()
               var fuckingYPosition = that.yPosition+'px';
               var path = this.getAttribute("data-route");
               var windowWidth = window.innerWidth / 2;
               
               // tl1.to(this.querySelector('.overlay_project'), { duration: 0, position: 'fixed', top: fuckingYPosition, width:'100%', height:'100vh' })
               document.querySelector('#projets').style.zIndex = 200;
               this.querySelector('.polygon').classList.add('noPolygon')
               // tl1.to(this.querySelector('.overlay_project'), { duration: 0.3, opacity:1 })
               if(this.classList.contains('right')){
                  tl1.to(this.querySelector('.wrapp_image'), { duration: .5, left: windowWidth, position: 'fixed', marginLeft: '0', transform: 'translateX(-50%)', delay: 0.1 })
               } else {
                  tl1.to(this.querySelector('.wrapp_image'), { duration: .5, left: windowWidth, position: 'fixed', marginLeft: '0', transform: 'translateX(-50%)', delay: 0.1 })
               }

               setTimeout(() => {
                  that.$emit('receive-click-project', true)
   
                  tl1.to(this.querySelector('.wrapp_image'), { duration: 0.6, position: 'fixed', top: fuckingYPosition, width:'100%', height:'100vh'})
                  tl1.to(this.querySelector('.wrapp_background'), { duration: 0.6, transform: 'scale(1)'}, '-=0.3')
                  tl1.to(this.querySelector('.wrapp_background img'), { duration:0.6, transform: 'translate(0)'}, '-=0.6')
                  tl1.add( function(){ 
                     const triggers = ScrollTrigger.getAll();
                     triggers.forEach((trigger) => {
                        trigger.kill();
                     })              
                     navigate.push({ name: path, params: { goProject: true }})
                  }, '+=0.4')
               }, 500);

            })
         });
      },
      gsapAnimation(){
         const pageContainer = document.querySelector('[data-scroll-container]')

         // PROJETS
         const projects = document.querySelectorAll('.wrapp_projects .project');
         projects.forEach((project) => {         


            // Wrapper image project
            const tl2 = gsap.timeline({
                  scrollTrigger: {trigger: project, scroller: pageContainer, start: `top+=35% bottom`, 
                  toggleActions: 'play play play reverse' 
            }});  
            tl2.to(project.querySelector('.wrapp_image .wrapper'), {
               height: '100%', duration: 0.6, ease: 'easeIn'     
            }) 
            tl2.from(project.querySelector('.background_color'), {
               height: '100%', duration: 0.4, ease: 'easeIn'     
            }) 


            // gsap.to(project.querySelector('.wrapp_image'), {
            //    scrollTrigger: {scroller: pageContainer, trigger: project, start: `top+=25% bottom`, toggleActions: 'play play play reverse' },
            //    clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', opacity:1, duration: 0.8, ease: 'easeIn'         
            // }); 
            // // Title projet
            gsap.to(project.querySelector('.title span'), {
               scrollTrigger: {scroller: pageContainer, trigger: project.querySelector('.description'), toggleActions: 'play play play reverse', start: `bottom bottom-=100`},
               transform: 'translateX(0)', duration: 0.7, delay: 0.3, ease: 'easeIn'
            }); 
            gsap.to(project.querySelector('.title'), {
               scrollTrigger: {scroller: pageContainer, trigger: project.querySelector('.description'), toggleActions: 'play play play reverse', start: `bottom bottom-=100`},
               color: 'rgba(255, 255, 255, 1)', duration: 0.4, delay: 0.3, ease: 'easeIn'
            }); 
            // // Details projet
            gsap.to(project.querySelector('.description .details'), {
               scrollTrigger: {scroller: pageContainer, trigger: project.querySelector('.description'), toggleActions: 'play play play reverse', start: `bottom bottom-=100`, end:'bottom bottom+=200'},
               transform: 'translateY(0)', opacity: 1, duration: 0.5, delay: 0.8, ease: 'easeIn'
            });  
         })      
         
         // BACKGROUND OVERLAY 
         gsap.to('#projets .background_overlay', {
            scrollTrigger: {scroller: pageContainer, trigger: '#projets', start: `bottom-=10% bottom`, end: `bottom+=400 bottom`, scrub: true},
            height: '65vh', 
         }) 
         
      
      }       
   },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/global.scss';
#projets{
   position:relative;
   z-index: 55;
   padding-bottom: 22vh;
   background:#111111;
   a{
      cursor:pointer;
   }
}
.wrapp_content{
   margin-top:-32vh
}
.background_overlay{
   width:100%;
   background:#000;
   height:0;
   position:absolute;
   bottom:0;
   z-index: 120;
}
#direction2{
    height: 200px;
    margin:0
}
.wrapp_projects{
   width: calc(100% - 80px);
   max-width: 1680px;
   padding-top:7vh;
   margin: 0 auto 0;
   position: relative;
}
@media screen and (max-width: 1580px) {
   .wrapp_content{margin-top:-35vh}
}
@media screen and (max-width: 980px) {
   .wrapp_projects {padding-top: 4vh;}
   #projets{padding-bottom: 20vh;}
}
@media screen and (max-width: 580px) {
   .wrapp_projects {padding-top: 4vh; width: calc(100% - 50px);}
   .wrapp_content{margin-top:-40vh; }
   #projets{padding-bottom: 25vh;}
   
}
</style>
