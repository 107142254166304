<template>
    <!-- Visuel image -->
    <div :class="'visuel ' + this.side + ' ' + this.size" ref="wrappVisuel">
        <div v-if="!!$slots.title" class="text" ref="text" data-scroll="" data-scroll-delay="0.1" :data-scroll-speed="(windowWidth < 980) ? 0 : 3">
            <div class="wrapp">
                <div class="title"><slot name="title"></slot></div>
                <div class="content"><slot name="content"></slot></div>
            </div>
        </div>
        <div class="wrapp_image" ref="wrappImage"  data-scroll="" data-scroll-delay="0.1" :data-scroll-speed="(windowWidth < 980) ? 0 : this.scrollSpeed">
            <img class="item" :src="require(`@/assets/img/${imageName}`)"/>
        </div>
    </div>
</template>
 
<script>
import { TimelineLite, gsap  } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"

export default {
    name: 'Visuels',
    props: {
      scrollSpeed: {type: Number, require: true},
      imageName: {type: String, require: true},
      side: {type: String, require: true},
      big: {type: Boolean, require: false, default: false},
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            size: 'normal'
        }
    },
    mounted () {
            setTimeout(() => {
            this.gsapAnimation()
        }, 300);
    }, 
    methods: {
        gsapAnimation(){
            const pageContainer = document.querySelector('#app'); 
            const { wrappVisuel, wrappImage, text } = this.$refs

            // Visual FadeIn 
            if (this.big == true){
                this.size = 'big'
                var startValue = "top+=5% bottom"
            } else {
                var startValue = "top+=35% bottom"
            }
            gsap.to(wrappImage, {
                scrollTrigger: {scroller: pageContainer, trigger: wrappVisuel, start: startValue, toggleActions: 'play play play reverse'},
                clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', opacity:1, duration: 0.8, ease: 'easeIn'         
            });   

            if(this.windowWidth < 980){
                gsap.from(text, {
                    scrollTrigger: {scroller: pageContainer, trigger: text, start: `top+=55% bottom`, toggleActions: 'play play play reverse'},
                    transform: 'translateY(15%)', opacity:0, duration: 0.6, ease: 'easeIn'         
                });   
            }
            // if(project.querySelector('.video') != null){
            //     gsap.to(wrappIimage, {
            //         scrollTrigger: {scroller: pageContainer, trigger: project, start: `top-=200 center`, end: 'bottom-=100 center',  
            //         onLeave: () => project.querySelector('.video').pause(),
            //         onLeaveBack: () => project.querySelector('.video').pause()
            //     },}); 
            // }
                  
        },
      
   },
}
</script>

<style scoped lang="scss">
// @import '@/assets/scss/global.scss';
#visuels{
     .visuel{
        position: relative;
        padding-top:6vh;  
        padding-bottom:6vh; 
        &.big{
            .text{
                top:15%;
            }
        }
        .text{
            position: absolute;
            left:auto;
            width: 25%;
            right:0;
            top:30%;
            text-align: left;
            &.is-inview .wrapp{
                opacity: 1;
            }
            .wrapp{
                opacity: 0;
                transition-duration:1.2s;
                // opacity:0;
                // transform:translateY(15%)
            }
            .title{
                font-size: 40px;
                font-weight: 800;
                color: #fff;
                line-height: 1.15;
                margin-bottom: 20px;
            }
            .content{
                color:#777;
                font-size: 18px;
                line-height: 1.5;
            }
        }  
        .wrapp_image{
            width:70%;
            overflow: hidden;
            display: block;
            opacity: 0;
            position:relative;
            clip-path: polygon(30% 10%, 70% 10%, 70% 90%, 30% 90%); 
           
            video{
                width: 100%;
            }
            .blank{
                width:100%;
                position: relative; 
                height: 100%;
            }
            .item{
                width:100%;
            }
        }
        &.right{
            .text{
                left: 0;
                text-align: right;
                right:auto;
            }
            .wrapp_image{
                margin-left:30%
            }
        }
    }
}

@media screen and (max-width: 1480px) {
    #visuels .visuel .text .title{font-size:35px;}
    #visuels .visuel .text .content{font-size:17px;}
    #visuels .visuel.right .text {top: 15%;}
}
@media screen and (max-width: 1280px) {
    #visuels .visuel .text .title{font-size:28px;}
    #visuels .visuel .text .content{font-size:16px;}
    #visuels .visuel.right .text {top: 15%;}
}
@media screen and (max-width: 980px) {
    #visuels .visuel .wrapp_image{width: 100% !important; }
    #visuels .visuel .text{position:relative; width: 100%;}
    #visuels .visuel {display: flex; flex-wrap: wrap; flex-direction: column-reverse;}
    #visuels .visuel.right .wrapp_image{width: 100% !important; margin-left: 0 !important;}
}
@media screen and (max-width: 580px) {
    #visuels .visuel .text .title {font-size: 23px;}
    #visuels .visuel .text .content {font-size: 15px;margin-top: -10px;}
}
</style>