<template>
  <div id="nav" class="container" ref="nav">
        <!-- Logo -->
        <div class="logo">
          <router-link to="/">Usubelli Sylvain</router-link>
        </div>
        <!-- Menu -->
        <div class="menu">
          <ul>
            <li class=""><a href="#" class="scrollToBtn hover_element" data-href="#about">A propos</a></li>
            <li class=""><a href="#" class="scrollToBtn hover_element" data-href="#projets">Projets</a></li>
            <li class=""><a href="#" class="scrollToBtn hover_element" data-href="#contact">Contact</a></li>
          </ul>
        </div>
  </div>
</template>

<script>
export default {
   name: 'Menu',
   mounted() {
   },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/global.scss';
#nav{
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 40px 0;
   position: absolute;
   left: 50%;
   transform: translateX(-50%) translateY(-100%);
   z-index: 100;
   transition-duration: 0.5s;
   transition-delay: 0.8s;
   transition-timing-function: ease-out;
   position: absolute;
   .logo{
      font-weight: 700;
      font-size: 23px;
      letter-spacing: -1.5px;
   }
   .menu{
      li{
         display: inline-block;
         a{
            margin:0 20px;
            font-weight: 600;
            transition-duration: 0.3s;
            &:hover{
               color:$base-color
            }
         }
      }
   }
   &.load{
      transform: translateX(-50%) translateY(0%);
   } 
}
@media screen and (max-width: 980px) {
   .menu{display: none;}
   #nav .logo {font-size: 21px;}
   #nav{padding:25px 0; transition-delay: 0s;}
}
</style>
