import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Sexbattle from '../views/Sexbattle.vue'
import Dezyr from '../views/Dezyr.vue'
import Adrenaline from '../views/Adrenaline.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/sexbattle',
    name: 'Sexbattle',
    component: Sexbattle,
  },
  {
    path: '/dezyr',
    name: 'Dezyr',
    component: Dezyr,
  },
  {
    path: '/adrenaline',
    name: 'Adrenaline',
    component: Adrenaline,
  }
]

const router = new VueRouter({
  mode: 'history', 
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  } 
})

export default router
