<template>
   <div id="about">
      <p id="direction" >
         <span class="big_title" data-scroll="" data-scroll-direction="horizontal" :data-scroll-speed="(windowWidth < 780) ? -4 : -8" data-scroll-target="#direction" >À propos <span>À propos</span> À propos <span>À propos</span> À propos</span>
      </p>
      <div class="container">
            <div class="wrapp_text columns">
               <div class="intro_text">
                  
                  <div data-scroll=""  data-scroll-delay="0.02" :data-scroll-speed="(windowWidth < 780) ? 1 : 2">
                     <p class="up_word"><span>Lorem ipsum dolor amet, consectetur adipiscing</span></p>
                     <p class="up_word"><span>sed do eiusmod tempor incididunt ut labore</span></p>
                     <p class="up_word"><span>ommodo viverra maecenas accumsan</span></p>
                  </div>
               </div>
               <div class="wrapp_column" data-scroll="" data-scroll-delay="0.02" :data-scroll-speed="(windowWidth < 780) ? 1.2 : 2.2">
                  <div class="about column">
                     <p class="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                  </div>
                  <div class="about column">
                     <p class="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                  </div>
               </div>
            </div>
      </div>
 
      <!-- Background -->
      <div class="background_overlay_P"></div>
   </div>
</template>

<script>
import { TimelineLite, gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
   name: 'Apropos',
   data() {
      return {
         windowWidth: window.innerWidth
      }
   },
   mounted() {
      // init gsapAnimation
       this.gsapAnimation() 
 
   },
   methods: {
      gsapAnimation(){
         const { bigTitle } = this.$refs
         const pageContainer = document.querySelector('[data-scroll-container]')
         const clientHeight = document.documentElement.clientHeight
    
         // Intro text 
         gsap.to('.intro_text .up_word span', {
            scrollTrigger: {scroller: pageContainer, trigger: '.intro_text', toggleActions: 'play play play reverse', start: `center bottom`},
            transform: 'translateX(0)', duration: 0.3, stagger: 0.2,ease: 'easeIn'});      
         // About
         gsap.to('.about', {
            scrollTrigger: {scroller: pageContainer, trigger: '.about', toggleActions: 'play play play reverse', start: 'center bottom'},
            transform: 'translateY(0)', opacity: 1, duration: 0.6, stagger: 0.2,ease: 'easeIn'
         });

         // Background
         gsap.to('.background_overlay_P', {
            scrollTrigger: {scroller: pageContainer, trigger: '#about', start: `center bottom`, end: `bottom top`, scrub: true},
            height: '100vh', duration: 0.3, 
         }) 
 
      }
   },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/global.scss';
 

#about{
   position:relative;
   z-index: 55;
   padding-top: 17vh;
   padding-bottom: 0;
   padding-bottom: 60vh;
}
.background_overlay_P{
   width:100%;
   background:#111111;
   height:0%;
   position:absolute;
   bottom:0;
   z-index: 50;
}
#direction{
   height:200px;
   margin:-10vh 0 60px
}
.wrapp_text{
   display: flex;
   text-align: left;
   margin-top: 12%;
   
   .intro_text{
      color:#fff;
      font-weight:700;
      font-size:26px;
      line-height: 1.2;
      min-width: 50%;
      text-align: left;
      .up_word{
         margin: 8px 0;;
      }
   }
   .wrapp_column{
      max-width:60%;
      display: flex;
      margin-top: -10px;
      .column {
         max-width: 50%;
         padding:0 20px;
         font-weight: 500;
         line-height: 1.6;
         opacity: 0;
         color: rgba(255,255,255,0.6);
         transform: translateY(30%),;
      }
   }
   
}


@media screen and (max-width: 1700px) {
   }
@media screen and (max-width: 1380px) {
   #about{padding-top: 16vh;}
   .wrapp_text .intro_text {min-width: 45%;}
}
@media screen and (max-width: 980px) {
   #direction {margin: 0vh 0 60px;}
   #about {padding-top: 10vw;}
   .wrapp_text {display: block; margin-top: 12%;}
   .wrapp_text .column {max-width: 100%; padding: 0;}
   .wrapp_text .wrapp_column {display: block; margin-top:0; max-width:100%;  width:100%}
   .wrapp_text .wrapp_column .column{display: block; max-width:100%; padding: 0; margin-bottom: 30px; width:100%}
   .wrapp_text .column .content{margin: 0;}
}
@media screen and (max-width: 580px) {
   .wrapp_text .wrapp_column { margin-top: 0;}
   .wrapp_text .intro_text  {font-size: 22px;}
   #about {padding-bottom: 57vh;}
}
</style>
